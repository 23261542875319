import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components';

const LargeImageContainer = styled.div`
width: 80%;
margin: auto;
`
const Image = styled.img`
width: 100%;
margin-top: 2em;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
   margin-top: 0em;
  }

`
const Caption = styled.div`
font-size: 0.9em;
color: #A1A5AA;
text-align: center;
padding: 0.2em 0em 2em 0em;
`


const LargeImage = props => (
    <Fade bottom duration={350} distance="1.6em" ssrFadeout>
    <LargeImageContainer >
        <Image src={props.image} />
        <Caption>{props.caption}</Caption>
    </LargeImageContainer>
    </Fade>
)

export default LargeImage