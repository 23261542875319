import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components';

const TextContainer = styled.div`
width: 40%;
line-height: 1.9em;
margin-left: 40%;
margin-top: 5em;
`
const TextTitle = styled.div`
font-size: 1.2em;
font-weight: 700;
margin-bottom: 0.8em;
`
const Image = styled.img`
width: 100%;
margin-top: 2em;
`
const Image2 = styled.img`
width: 100%;
margin-top: 2em;
`

const Image3 = styled.img`
width: 100%;
margin-top: 2em;
`


const TextParagraph = styled.div`
font-size: 1em;
line-height: 2em;
`

const TextBlockRight = props => (
	<div>
	    <TextContainer >
	    	<Fade bottom duration={350} distance="1.6em" ssrFadeout>
	    		<TextTitle>{props.title}</TextTitle>
	        	<TextParagraph>{props.paragraph}</TextParagraph>
	    		<Image src={props.image} />
	    		<Image2 src={props.image2} />
	    		<Image3 src={props.image3} />
	    	</Fade>
	    </TextContainer>
	</div>
)

export default TextBlockRight