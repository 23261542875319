import React from 'react'
import styled from 'styled-components';

const HeroImageContainer = styled.div`
width: 100%;
`
const Image = styled.img`
width: 100%;
margin-top: 2em;
`

const HeroImage = props => (
    <HeroImageContainer >
        <Image src={props.image} />
    </HeroImageContainer>
)

export default HeroImage