import React, { Component } from 'react'
import Link from 'gatsby-link'
import styled from 'styled-components';
 
 const FooterContainer = styled.div`
  width: 100%;
  margin: 4em auto; 
  font-weight: 700;
  text-align: center;
`
 const LinkContainer = styled.div`
  margin: auto;
`


const FooterCaseStudyBack = props => (
  <div>
    <FooterContainer>
    <LinkContainer>
           <Link to="/">← Back to home</Link>
    </LinkContainer>
    </FooterContainer>
  </div>
)

export default FooterCaseStudyBack