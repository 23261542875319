import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components';

const Title = styled.div`
font-size: 1.3em;
font-variation-settings: 'wght' 700;
margin-bottom: 0.4em;
`

const Headline = styled.div`
font-size: 2em;
font-variation-settings: 'wght' 700;
width: 80%;
float: right;
margin-bottom: 0.4em;
color: rgba(5,18,32,0.4);
`

const Description = styled.div`
font-size: 1em;
font-variation-settings: 'wght' 400;
line-height: 1.8em;
`
const TextBlock = styled.div`
width: 80%;
margin: 2em 0em;
`

const ContainerLeft = styled.div`
width: 50%;
align-items: flex-start;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
}
`
const ContainerRight = styled.div`
width: 50%;
align-items: flex-start;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
}
`

const MainContainer = styled.div`
width: 80%;
height: 100%;
margin: auto;
margin-top: 8em;
margin-bottom: 8em;
display: flex;
flex-direction: row;
flex-flow: row wrap;
justify-content: space-around;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  margin-top: 2em;
  margin-bottom: 2em;
}

`

const IntroCaseStudy2 = props => (

	    <MainContainer>
	    	<ContainerLeft>
	    		<TextBlock>
		    		<Fade bottom duration={450} distance="1.4em" ssrFadeout>
		    			<Title>{props.title1}</Title>
		    			<Description>{props.description1}</Description>
		    		</Fade>
		    	</TextBlock>
			</ContainerLeft>
			<ContainerRight>
				<TextBlock>
				<Fade bottom duration={450} delay={100} distance="1.4em" ssrFadeout>
		    			<Title>{props.title2}</Title>
		    			<Description>{props.description2}</Description>
		    		</Fade>
		    	</TextBlock>
			</ContainerRight>
		</MainContainer>

)

export default IntroCaseStudy2