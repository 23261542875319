import React from 'react'
import Fade from 'react-reveal/Fade'
import styled from 'styled-components'
import HeroImage from '../components/heroImage';

const Title = styled.span`
font-size: 1em;
color: #051220;
font-weight: 600;
margin-bottom: 0.4em;
`

const Description = styled.span`
font-size: 1.4em;
color: #A1A5AA;
font-weight: 600;
margin-bottom: 0.4em;
`

const SmallTitle = styled.div`
font-size: 0.9em;
text-transform: uppercase;
font-weight: 600;
color: #A1A5AA;
`

const SmallDescription = styled.div`
font-size: 0.9em;
font-weight: 400;
line-height: 1.8em;
margin-bottom: 2em;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  margin-bottom: 1.6em;
}
`

const ContainerLeft = styled.div`
width: 50%;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
  margin-bottom: 4em;
}
`
const ContainerRight = styled.div`
width: 20%;
flex-direction: column;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 100%;
}
`

const MainContainer = styled.div`
width: 94%;
margin: auto;
margin-top: 0em;
margin-bottom: 0em;
display: flex;
flex-direction: row;
justify-content: space-around;

@media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 90%;
  margin-top: 2em;
  margin-bottom: 2em;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
}

`

const IntroCaseStudy3 = props => (

	    <MainContainer>
	    	<ContainerLeft>
		    		<Fade bottom duration={450} delay={300} distance="1.4em" ssrFadeout>
		    			<Description><Title>{props.title}</Title>{props.description}</Description>
		    		</Fade>
			</ContainerLeft>
			<ContainerRight>
				<Fade bottom duration={450} delay={400} distance="1.4em" ssrFadeout>
		    			<SmallTitle>{props.titleRight1}</SmallTitle>
		    			<SmallDescription>{props.descriptionRight1}</SmallDescription>
		    			<SmallTitle>{props.titleRight2}</SmallTitle>
		    			<SmallDescription>{props.descriptionRight2}</SmallDescription>
		    			<SmallTitle>{props.titleRight3}</SmallTitle>
		    			<SmallDescription>{props.descriptionRight3}</SmallDescription>
		    	</Fade>
			</ContainerRight>
		</MainContainer>

)

export default IntroCaseStudy3