import React, { Component } from 'react'
import HeroImage from '../components/heroImage'
import Fade from 'react-reveal/Fade'
import styled, { keyframes } from 'styled-components';

const animationTitle = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-40px);
  }
  100% {
      opacity: 1;
      transform: translateY(0px);  
  }
`
const animationSubtitle = keyframes`
  0% {
      opacity: 0;
      transform: translateY(-20px);      
  }
  100% {
      opacity: 1;
      transform: translateY(0px);
  }
`

 const MainContainer = styled.div`
  width: 100%;
  left: 0;
  background-color: #fff;
  display: flex;
  align-items:center;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
   height: auto;
   padding-top: 4em;
  }

`
 const TextContainer = styled.div`
  width: 80%;
  padding: 10em 0em 6em 0em;
  margin: auto;
  align-items: flex;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
    width: 90%;
    padding: 2em 0em;
  }

`
 const Title = styled.div`
  width: 55%;
  font-weight: 600;
  font-size: 3em;
  margin-bottom: 0.2em;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
   width: 100%;
   font-size: 2.6em;
  }
`
 const Subtitle = styled.div`
  width: 45%;
  font-weight: 700;
  font-size: 2em;
  color: #8B9096;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
   width: 80%;
   font-size: 1.6em;
  }
`


const HeroCaseStudy = props => (
  <div>
      <MainContainer>
        <TextContainer>
        <Fade bottom duration={450} delay={200} distance="2em" ssrFadeout>
          <Title>{props.title}</Title>
        </Fade>
        <Fade bottom duration={450} delay={200} distance="2em" ssrFadeout>
          <Subtitle>{props.subtitle}</Subtitle>
        </Fade>
        </TextContainer>
      </MainContainer>
        
  </div>
)

export default HeroCaseStudy