import React, {Component} from 'react';
import PropTypes from 'prop-types';
import Fade from 'react-reveal/Fade'
import styled from 'styled-components';

const TextContainer = styled.div`
width: 80%;
line-height: 1.9em;
margin: auto;
padding-top: 4em;
margin-top: 0em;

  @media only screen and (min-width: 375px) and (max-width: 812px) {
  width: 80%
  }
`
const TextTitle = styled.div`
font-size: 1.4em;
font-weight: 600;
padding-bottom: 0.4em;
width: 50%;
margin: auto;
`

const Image = styled.img`
width: 100%;
margin-top: 2em;
`

const Image2 = styled.img`
width: 100%;
margin-top: 2em;
`

const Image3 = styled.img`
width: 100%;
margin-top: 2em;
`
const Caption = styled.div`
font-size: 0.9em;
color: #A1A5AA;
text-align: center;
padding: 0.2em 0em 0em 0em;
`

const TextParagraph = styled.div`
font-size: 1.1em;
line-height: 1.8em;
color: #051220;
width: 50%;
margin: auto;
`

class TextBlockCentered extends Component {
	constructor(props){
		super(props);
		this.props = props;
		console.log(this.props.image)
	}
	render () {
		let videoComponent = null;
		if(this.props.video){
			videoComponent = (
				<div style={{marginTop:"2em", paddingTop:"56.25%", position:"relative"}}>
					<embed  width="100%" height="100%" style={{position: "absolute", top:"0"}} src={this.props.video} />
				</div>
			);
		}
		if (!this.props.image){

			 return (
				<div>
	    			<TextContainer >
	    				<Fade bottom duration={350} distance="1.6em" ssrFadeout>
	    					<TextTitle>{this.props.title}</TextTitle>
	        				<TextParagraph>{this.props.paragraph}</TextParagraph>
	        			</Fade>
	        			{videoComponent}
	        		
	    			</TextContainer>
				</div>
	        	)

		} else if (!this.props.image2){
			return (
				<div>
				    <TextContainer >
				    	<Fade bottom duration={350} distance="1.6em" ssrFadeout>
				    		<TextTitle>{this.props.title}</TextTitle>
				        	<TextParagraph>{this.props.paragraph}</TextParagraph>
				        	<Image src={this.props.image} />
				        	<Caption>{this.props.caption}</Caption>

				        </Fade>
				        {videoComponent}
		
				    </TextContainer>
				</div>
				)

		} else if (!this.props.image3) {
			return (
				<div>
				    <TextContainer >
				    	<Fade bottom duration={350} distance="1.6em" ssrFadeout>
				    		<TextTitle>{this.props.title}</TextTitle>
				        	<TextParagraph>{this.props.paragraph}</TextParagraph>
				        	<Image src={this.props.image} />
				        	<Caption>{this.props.caption}</Caption>
				        	<Image src={this.props.image2} />
				        	<Caption>{this.props.caption2}</Caption>

				        </Fade>
				        {videoComponent}
				
	    			</TextContainer>
				</div>
				)
		} else {
		return (
			<div>
			    <TextContainer >
			    	<Fade bottom duration={350} distance="1.6em" ssrFadeout>
			    		<TextTitle>{this.props.title}</TextTitle>
			        	<TextParagraph>{this.props.paragraph}</TextParagraph>
			        	<Image src={this.props.image} />
			        	<Caption>{this.props.caption}</Caption>
			        	<Image src={this.props.image2} />
			        	<Caption>{this.props.caption2}</Caption>
			        	<Image src={this.props.image3} />
			        	<Caption>{this.props.caption3}</Caption>
			    	</Fade>
			        {videoComponent}
			        
			    </TextContainer>
			</div>
			)
		}
	}
}

TextBlockCentered.propTypes = {
      children: PropTypes.func,
  }

export default TextBlockCentered
